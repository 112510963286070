<script lang="ts">
	import cn from 'classnames';
	export let color:
		| 'green'
		| 'green-emerald'
		| 'red'
		| 'gray-hitgray'
		| 'red-orientalpink'
		| 'gray-shark'
		| 'red-vividtangerine'
		| 'blue'
		| 'yellow';
	export let size: 'small' | 'large' | undefined = undefined;
	export let withArrow: boolean = false;

	const resolveBgColor = (color: string) => {
		return color === 'green'
			? 'bg-green'
			: color === 'green-emerald'
				? 'bg-green-emerald'
				: color === 'red-vividtangerine'
					? 'bg-red-vividtangerine'
					: color === 'gray-hitgray'
						? 'bg-gray-hitgray'
						: color === 'red-orientalpink'
							? 'bg-red-orientalpink'
							: color === 'gray-shark'
								? 'bg-gray-shark text-white'
								: color === 'red'
									? 'bg-red'
									: color === 'yellow'
										? 'bg-yellow'
										: color === 'blue'
											? 'bg-blue text-white'
											: '';
	};
</script>

<span
	class={cn(
		'shrink-0 grow-0 self-start px-4 py-2 leading-none text-gray-shark',
		size === 'small' ? 'text-sm' : 'text-base',
		resolveBgColor(color),
		withArrow && `tag-arrow [&:before]:${resolveBgColor(color)}`,
		$$restProps.class
	)}
>
	<slot />
</span>
